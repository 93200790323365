<template>
  <div id="my-charging-points">
    <HeaderNavigation title="mes points de recharge" />
    <b-container class="main-container">
      <b-row>
        <b-col>
          <ChargingPointCardDetailed
            v-for="chargingPoint in chargingPoints"
            :key="chargingPoint.id"
            :chargingPoint="chargingPoint"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HeaderNavigation from '@/components/HeaderNavigation.vue'
import ChargingPointCardDetailed from '@/components/ChargingPoints/ChargingPointCardDetailed.vue'

export default {
  name: 'my-charging-points',
  components: { HeaderNavigation, ChargingPointCardDetailed },
  computed: {
    chargingPoints() {
      return this.$store.getters.chargingPoints
    }
  }
}
</script>

<style lang="scss">
@import '@/styles.scss';

.charging-point {
  background-color: $base-text-back-color;
  border-radius: 10px;
  border: none;
  margin-bottom: 10px;
  a {
    .card-body {
      text-decoration: none;
      padding: 15px 15px 10px 15px;

      .card-subtitle {
        color: #cecece;
        font-size: 14px;
        opacity: 1;
        letter-spacing: 1px;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  svg {
    fill: $dark;
    stroke: $dark;
    vertical-align: top;
  }
  &.invalid-data {
    background-color: $invalid-data-color;
    a {
      .card-body {
        .card-subtitle {
          color: #fff;
          opacity: 0.5;
        }
      }
    }
    &:hover {
      svg {
        fill: #fff;
        stroke: #fff;
      }
      .title-headline {
        color: #fff;
      }
    }
  }
  &:active {
    background-color: #ff995a;
  }
}
</style>
