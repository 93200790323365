var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "11.568",
        height: "23.312",
        viewBox: "0 0 11.568 23.312"
      }
    },
    [
      _c("g", { attrs: { transform: "translate(0.5 0.5)" } }, [
        _c("path", {
          staticClass: "a",
          attrs: {
            d:
              "M8.272,10.322H.924A.926.926,0,0,1,0,9.4V.924A.926.926,0,0,1,.924,0H8.272A.926.926,0,0,1,9.2.924V9.405A.929.929,0,0,1,8.272,10.322Z",
            transform: "translate(0 0)"
          }
        }),
        _c("line", {
          staticClass: "a",
          attrs: { y2: "11.99", transform: "translate(4.598 10.322)" }
        }),
        _c("path", {
          staticClass: "a",
          attrs: {
            d:
              "M0,0H.527a.853.853,0,0,1,.852.852v4.6A.853.853,0,0,1,.527,6.3H0",
            transform: "translate(9.189 2.093)"
          }
        }),
        _c("line", {
          staticClass: "b",
          attrs: { x2: "9.189", transform: "translate(0 22.312)" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }