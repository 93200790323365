var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    { staticClass: "media charging-point", attrs: { to: _vm.cpRoute } },
    [
      _c("div", { staticClass: "side-image" }, [_c("ChargingPointIcon")], 1),
      _c(
        "b-media-body",
        [
          _c("span", { staticClass: "title-headline text-uppercase" }, [
            _vm._v("Point de recharge #" + _vm._s(_vm.chargingPoint.id))
          ]),
          _c("ChargingPointResume", {
            attrs: { chargingPoint: _vm.chargingPoint }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }