<template>
  <router-link :to="cpRoute" class="media charging-point">
    <div class="side-image">
      <ChargingPointIcon />
    </div>
    <b-media-body>
      <span class="title-headline text-uppercase"
        >Point de recharge #{{ chargingPoint.id }}</span
      >
      <ChargingPointResume :chargingPoint="chargingPoint" />
    </b-media-body>
  </router-link>
</template>

<script>
import ChargingPointIcon from '@/components/svg/ChargingPointIcon.vue'
import ChargingPointResume from '@/components/ChargingPoints/ChargingPointResume.vue'
import { Routes } from '@/router.js'

export default {
  name: 'charging-point-card-detailed',
  components: { ChargingPointIcon, ChargingPointResume },
  props: {
    chargingPoint: { type: Object, required: true }
  },
  computed: {
    cpRoute() {
      return {
        ...Routes.MY_CHARGING_POINT,
        params: { id: this.chargingPoint.id }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles.scss';

.charging-point {
  margin: 20px 0;

  &.media {
    align-items: normal;

    div.side-image {
      padding: 24px;
      background-color: $provider-yellow;
      border-radius: 10px 0 0 10px;

      svg {
        align-self: flex-start;
        stroke: #a0a0a0;
      }
    }

    .media-body {
      margin: 15px;
      margin-bottom: 10px;
    }
  }

  &:hover,
  &:active {
    background-color: #fdfdfd;
    text-decoration: none;

    div.side-image {
      svg {
        stroke: #fdfdfd;
      }
    }
  }

  &:active {
    .title-headline {
      opacity: 0.5;
    }
  }
}
</style>
<style lang="scss">
.charging-point {
  &:active {
    .cp-resume {
      fieldset.form-group legend.col-form-label,
      span {
        opacity: 0.5;
      }
    }
  }
}
</style>
